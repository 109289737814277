/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Interface, type ContractRunner } from "ethers";
import type { Router, RouterInterface } from "../Router";

const _abi = [
  {
    type: "constructor",
    inputs: [
      {
        name: "wrappedNative",
        type: "address",
        internalType: "address",
      },
      {
        name: "armProxy",
        type: "address",
        internalType: "address",
      },
    ],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "MAX_RET_BYTES",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint16",
        internalType: "uint16",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "acceptOwnership",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "applyRampUpdates",
    inputs: [
      {
        name: "onRampUpdates",
        type: "tuple[]",
        internalType: "struct Router.OnRamp[]",
        components: [
          {
            name: "destChainSelector",
            type: "uint64",
            internalType: "uint64",
          },
          {
            name: "onRamp",
            type: "address",
            internalType: "address",
          },
        ],
      },
      {
        name: "offRampRemoves",
        type: "tuple[]",
        internalType: "struct Router.OffRamp[]",
        components: [
          {
            name: "sourceChainSelector",
            type: "uint64",
            internalType: "uint64",
          },
          {
            name: "offRamp",
            type: "address",
            internalType: "address",
          },
        ],
      },
      {
        name: "offRampAdds",
        type: "tuple[]",
        internalType: "struct Router.OffRamp[]",
        components: [
          {
            name: "sourceChainSelector",
            type: "uint64",
            internalType: "uint64",
          },
          {
            name: "offRamp",
            type: "address",
            internalType: "address",
          },
        ],
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "ccipSend",
    inputs: [
      {
        name: "destinationChainSelector",
        type: "uint64",
        internalType: "uint64",
      },
      {
        name: "message",
        type: "tuple",
        internalType: "struct Client.EVM2AnyMessage",
        components: [
          {
            name: "receiver",
            type: "bytes",
            internalType: "bytes",
          },
          {
            name: "data",
            type: "bytes",
            internalType: "bytes",
          },
          {
            name: "tokenAmounts",
            type: "tuple[]",
            internalType: "struct Client.EVMTokenAmount[]",
            components: [
              {
                name: "token",
                type: "address",
                internalType: "address",
              },
              {
                name: "amount",
                type: "uint256",
                internalType: "uint256",
              },
            ],
          },
          {
            name: "feeToken",
            type: "address",
            internalType: "address",
          },
          {
            name: "extraArgs",
            type: "bytes",
            internalType: "bytes",
          },
        ],
      },
    ],
    outputs: [
      {
        name: "",
        type: "bytes32",
        internalType: "bytes32",
      },
    ],
    stateMutability: "payable",
  },
  {
    type: "function",
    name: "getArmProxy",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "address",
        internalType: "address",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "getFee",
    inputs: [
      {
        name: "destinationChainSelector",
        type: "uint64",
        internalType: "uint64",
      },
      {
        name: "message",
        type: "tuple",
        internalType: "struct Client.EVM2AnyMessage",
        components: [
          {
            name: "receiver",
            type: "bytes",
            internalType: "bytes",
          },
          {
            name: "data",
            type: "bytes",
            internalType: "bytes",
          },
          {
            name: "tokenAmounts",
            type: "tuple[]",
            internalType: "struct Client.EVMTokenAmount[]",
            components: [
              {
                name: "token",
                type: "address",
                internalType: "address",
              },
              {
                name: "amount",
                type: "uint256",
                internalType: "uint256",
              },
            ],
          },
          {
            name: "feeToken",
            type: "address",
            internalType: "address",
          },
          {
            name: "extraArgs",
            type: "bytes",
            internalType: "bytes",
          },
        ],
      },
    ],
    outputs: [
      {
        name: "fee",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "getOffRamps",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "tuple[]",
        internalType: "struct Router.OffRamp[]",
        components: [
          {
            name: "sourceChainSelector",
            type: "uint64",
            internalType: "uint64",
          },
          {
            name: "offRamp",
            type: "address",
            internalType: "address",
          },
        ],
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "getOnRamp",
    inputs: [
      {
        name: "destChainSelector",
        type: "uint64",
        internalType: "uint64",
      },
    ],
    outputs: [
      {
        name: "",
        type: "address",
        internalType: "address",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "getSupportedTokens",
    inputs: [
      {
        name: "chainSelector",
        type: "uint64",
        internalType: "uint64",
      },
    ],
    outputs: [
      {
        name: "",
        type: "address[]",
        internalType: "address[]",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "getWrappedNative",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "address",
        internalType: "address",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "isChainSupported",
    inputs: [
      {
        name: "chainSelector",
        type: "uint64",
        internalType: "uint64",
      },
    ],
    outputs: [
      {
        name: "",
        type: "bool",
        internalType: "bool",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "isOffRamp",
    inputs: [
      {
        name: "sourceChainSelector",
        type: "uint64",
        internalType: "uint64",
      },
      {
        name: "offRamp",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "",
        type: "bool",
        internalType: "bool",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "owner",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "address",
        internalType: "address",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "recoverTokens",
    inputs: [
      {
        name: "tokenAddress",
        type: "address",
        internalType: "address",
      },
      {
        name: "to",
        type: "address",
        internalType: "address",
      },
      {
        name: "amount",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "routeMessage",
    inputs: [
      {
        name: "message",
        type: "tuple",
        internalType: "struct Client.Any2EVMMessage",
        components: [
          {
            name: "messageId",
            type: "bytes32",
            internalType: "bytes32",
          },
          {
            name: "sourceChainSelector",
            type: "uint64",
            internalType: "uint64",
          },
          {
            name: "sender",
            type: "bytes",
            internalType: "bytes",
          },
          {
            name: "data",
            type: "bytes",
            internalType: "bytes",
          },
          {
            name: "destTokenAmounts",
            type: "tuple[]",
            internalType: "struct Client.EVMTokenAmount[]",
            components: [
              {
                name: "token",
                type: "address",
                internalType: "address",
              },
              {
                name: "amount",
                type: "uint256",
                internalType: "uint256",
              },
            ],
          },
        ],
      },
      {
        name: "gasForCallExactCheck",
        type: "uint16",
        internalType: "uint16",
      },
      {
        name: "gasLimit",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "receiver",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "success",
        type: "bool",
        internalType: "bool",
      },
      {
        name: "retData",
        type: "bytes",
        internalType: "bytes",
      },
      {
        name: "gasUsed",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "setWrappedNative",
    inputs: [
      {
        name: "wrappedNative",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "transferOwnership",
    inputs: [
      {
        name: "to",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "typeAndVersion",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "string",
        internalType: "string",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "event",
    name: "MessageExecuted",
    inputs: [
      {
        name: "messageId",
        type: "bytes32",
        indexed: false,
        internalType: "bytes32",
      },
      {
        name: "sourceChainSelector",
        type: "uint64",
        indexed: false,
        internalType: "uint64",
      },
      {
        name: "offRamp",
        type: "address",
        indexed: false,
        internalType: "address",
      },
      {
        name: "calldataHash",
        type: "bytes32",
        indexed: false,
        internalType: "bytes32",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "OffRampAdded",
    inputs: [
      {
        name: "sourceChainSelector",
        type: "uint64",
        indexed: true,
        internalType: "uint64",
      },
      {
        name: "offRamp",
        type: "address",
        indexed: false,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "OffRampRemoved",
    inputs: [
      {
        name: "sourceChainSelector",
        type: "uint64",
        indexed: true,
        internalType: "uint64",
      },
      {
        name: "offRamp",
        type: "address",
        indexed: false,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "OnRampSet",
    inputs: [
      {
        name: "destChainSelector",
        type: "uint64",
        indexed: true,
        internalType: "uint64",
      },
      {
        name: "onRamp",
        type: "address",
        indexed: false,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "OwnershipTransferRequested",
    inputs: [
      {
        name: "from",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "to",
        type: "address",
        indexed: true,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "OwnershipTransferred",
    inputs: [
      {
        name: "from",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "to",
        type: "address",
        indexed: true,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "error",
    name: "BadARMSignal",
    inputs: [],
  },
  {
    type: "error",
    name: "FailedToSendValue",
    inputs: [],
  },
  {
    type: "error",
    name: "InsufficientFeeTokenAmount",
    inputs: [],
  },
  {
    type: "error",
    name: "InvalidMsgValue",
    inputs: [],
  },
  {
    type: "error",
    name: "InvalidRecipientAddress",
    inputs: [
      {
        name: "to",
        type: "address",
        internalType: "address",
      },
    ],
  },
  {
    type: "error",
    name: "OffRampMismatch",
    inputs: [
      {
        name: "chainSelector",
        type: "uint64",
        internalType: "uint64",
      },
      {
        name: "offRamp",
        type: "address",
        internalType: "address",
      },
    ],
  },
  {
    type: "error",
    name: "OnlyOffRamp",
    inputs: [],
  },
  {
    type: "error",
    name: "UnsupportedDestinationChain",
    inputs: [
      {
        name: "destChainSelector",
        type: "uint64",
        internalType: "uint64",
      },
    ],
  },
] as const;

export class Router__factory {
  static readonly abi = _abi;
  static createInterface(): RouterInterface {
    return new Interface(_abi) as RouterInterface;
  }
  static connect(address: string, runner?: ContractRunner | null): Router {
    return new Contract(address, _abi, runner) as unknown as Router;
  }
}
